import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Drish Technologies</h1>
        <p>Your Innovation Partner</p>
      </header>

      <section className="App-section">
        <h2>Welcome to Drish Technologies</h2>
        <p>
          Drish Technologies is a leading technology company dedicated to innovation and excellence. Our team specializes in creating cutting-edge solutions to meet your business needs.
        </p>
        <p>
          Explore our services and discover how Drish Technologies can empower your business in the digital era.
        </p>
      </section>

      <footer className="App-footer">
        <p>&copy; 2024 Drish Technologies. All rights reserved..</p>
      </footer>
    </div>
  );
}

export default App;
